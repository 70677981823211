* {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
}

html, body, #root {
    height: 100%;
    min-height: 100%;
}

